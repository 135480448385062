export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';

export const SET_WELCOME_MESSAGE = 'SET_WELCOME_MESSAGE';

export const SET_USER_INFO = 'SET_USER_INFO';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const SET_SUBSCRIPTION_KEYS = 'SET_SUBSCRIPTION_KEYS';

export const SET_CONFIRMATION_NUMBER = 'SET_CONFIRMATION_NUMBER';

export const SET_ALL_PRODUCTS_DATA = 'SET_ALL_PRODUCTS_DATA';
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';

export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';

export const CONTEXT_MENU_SET_OPEN_STATE = 'CONTEXT_MENU_SET_OPEN_STATE';
export const CONTEXT_MENU_SET_CLOSE_ALL_STATE =
  'CONTEXT_MENU_SET_CLOSE_ALL_STATE';

export const SELECT_PRODUCT_API_VERSION = 'SELECT_PRODUCT_API_VERSION';
export const SET_DATA_SAMPLE = 'SET_DATA_SAMPLE';

export const SET_MODAL_WINDOW_OPEN = 'SET_MODAL_WINDOW_OPEN';
export const SET_MODAL_WINDOW_CLOSE = 'SET_MODAL_WINDOW_CLOSE';

export const SET_ERROR_PAGE_INFO = 'SET_ERROR_PAGE_INFO';
