import React from 'react';
import GlobalContextProvider from './src/context/GlobalContextProvider';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig from './okta-config';
import { navigate } from 'gatsby';
import GraphQLProvider from './src/components/common/graphql-provider/graphql-provider';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

export const wrapRootElement = ({ element }) => {
  /**
   * This function is used to redirect the user back to the previous page, after signing in
   */
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <GlobalContextProvider>
        <GraphQLProvider>{element}</GraphQLProvider>
      </GlobalContextProvider>
    </Security>
  );
};

/**
 * This is added for accessibility reasons
 */
export const onRouteUpdate = () => {
  const topElement = document.getElementById('top-element');
  const sessionBanner = document.getElementById('session-banner'); // The session banner component focuses on the banner
  if (topElement && !sessionBanner) {
    topElement.focus();
  }
};
