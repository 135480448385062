import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import fetch from 'node-fetch';

/**
 * This file is used to configure the Apollo/GraphQL for React front-end.
 */

const httpLink = createHttpLink({
  uri: process.env.GATSBY_MDMP_API_ENDPOINT,
  fetch: fetch as any,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('networkError', networkError);
  console.log('graphQLErrors', graphQLErrors);
});

export default (accessToken: string): ApolloClient<NormalizedCacheObject> => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(accessToken && {
          authorization: `Bearer ${accessToken}`,
        }),
      },
    }));

    return forward(operation);
  });

  return new ApolloClient({
    link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};
