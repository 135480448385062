exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-login-callback-jsx": () => import("./../../../src/pages/login/callback.jsx" /* webpackChunkName: "component---src-pages-login-callback-jsx" */),
  "component---src-pages-login-start-jsx": () => import("./../../../src/pages/login/start.jsx" /* webpackChunkName: "component---src-pages-login-start-jsx" */),
  "component---src-templates-about-us-page-tsx": () => import("./../../../src/templates/AboutUsPage.tsx" /* webpackChunkName: "component---src-templates-about-us-page-tsx" */),
  "component---src-templates-all-products-page-tsx": () => import("./../../../src/templates/AllProductsPage.tsx" /* webpackChunkName: "component---src-templates-all-products-page-tsx" */),
  "component---src-templates-confirmation-page-tsx": () => import("./../../../src/templates/ConfirmationPage.tsx" /* webpackChunkName: "component---src-templates-confirmation-page-tsx" */),
  "component---src-templates-generic-error-page-tsx": () => import("./../../../src/templates/GenericErrorPage.tsx" /* webpackChunkName: "component---src-templates-generic-error-page-tsx" */),
  "component---src-templates-help-product-help-page-tsx": () => import("./../../../src/templates/help/ProductHelpPage.tsx" /* webpackChunkName: "component---src-templates-help-product-help-page-tsx" */),
  "component---src-templates-help-send-feedback-page-tsx": () => import("./../../../src/templates/help/SendFeedbackPage.tsx" /* webpackChunkName: "component---src-templates-help-send-feedback-page-tsx" */),
  "component---src-templates-notifications-page-tsx": () => import("./../../../src/templates/NotificationsPage.tsx" /* webpackChunkName: "component---src-templates-notifications-page-tsx" */),
  "component---src-templates-payment-page-tsx": () => import("./../../../src/templates/PaymentPage.tsx" /* webpackChunkName: "component---src-templates-payment-page-tsx" */),
  "component---src-templates-pricing-page-tsx": () => import("./../../../src/templates/PricingPage.tsx" /* webpackChunkName: "component---src-templates-pricing-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-search-results-page-tsx": () => import("./../../../src/templates/SearchResultsPage.tsx" /* webpackChunkName: "component---src-templates-search-results-page-tsx" */),
  "component---src-templates-user-user-page-tsx": () => import("./../../../src/templates/user/UserPage.tsx" /* webpackChunkName: "component---src-templates-user-user-page-tsx" */),
  "component---src-templates-welcome-page-tsx": () => import("./../../../src/templates/WelcomePage.tsx" /* webpackChunkName: "component---src-templates-welcome-page-tsx" */)
}

