import { ApolloProvider } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import React, { ReactElement } from 'react';
import graphqlClient from '../../../graphql-client';

type Props = {
  children: ReactElement | ReactElement[];
};

/**
 * This component was created since hooks cannot be used from gatsby-browser.js
 * The Okta hook is used to get the access token and provide it to the GraphQL client
 * @param props
 * @returns
 */
const GraphQLProvider: React.FunctionComponent<Props> = props => {
  // IMPORTANT: Keep this conditional, or else the build will fail
  const { authState } = useOktaAuth ? useOktaAuth() : { authState: null };

  return (
    <ApolloProvider client={graphqlClient(authState?.accessToken?.accessToken)}>
      {props.children}
    </ApolloProvider>
  );
};

export default GraphQLProvider;
