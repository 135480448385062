export default {
  oidc: {
    clientId: process.env.GATSBY_PUBLIC_SECURE_CLIENT_ID,
    issuer:
      process.env.GATSBY_PUBLIC_SECURE_ISSUER_ENDPOINT +
      process.env.GATSBY_PUBLIC_SECURE_ISSUER_PATH,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false,
    tokenManager: {
      autoRenew: false,
    },
  },
};
