import { gql } from '@apollo/client';

export const SESSION_EXPIRED = 'sessionExpired';
export const SECONDS_TO_SHOW_SESSION_EXPIRY_WARNING = 600; // In seconds

// Configuration settings for language
export const LANG_CONFIG = {
  LANG: {
    DEFAULT_LANGUAGE_LOCALE: 'en-CA',
    LANGUAGES: {
      ENGLISH: {
        LOCALE: 'en-CA',
        LABEL: 'English',
        SHORT_LABEL: 'en',
      },
      FRENCH: {
        LOCALE: 'fr-CA',
        LABEL: 'French',
        SHORT_LABEL: 'fr',
      },
    },
  },
  EN_Prefix: '/',
  FR_Prefix: '/fr/',
};

// List routes for all pages (both server-rendered and client-rendered)
// NOTE: Other files should not use this variable directly to create links because of nested routes. Use getPageLink instead
// The client-side components handle the actual page routing (subPaths) within the component file.
// Client-side components must have a PAGES object with all the subPaths specified in the component.
export const SITE_CONFIG = {
  WELCOME_PAGE: {
    EN: '',
    FR: '',
    COMPONENT: './src/templates/WelcomePage.tsx',
  },
  ALL_PRODUCTS_PAGE: {
    EN: 'all-products',
    FR: 'tous-les-produits',
    COMPONENT: './src/templates/AllProductsPage.tsx',
  },
  PRODUCT: {
    EN: 'product',
    FR: 'produit',
    COMPONENT: './src/templates/ProductPage.tsx',
    PAGES: {
      // The list of components under the user route (consistent with the routes specified in the component)
      PRODUCT_DETAILS: {
        EN: '/details',
        FR: '/details',
      },
    },
  },
  SEARCH_RESULTS_PAGE: {
    EN: 'search',
    FR: 'chercher',
    COMPONENT: './src/templates/SearchResultsPage.tsx',
  },
  PRICING: {
    EN: 'pricing',
    FR: 'tarification',
    COMPONENT: './src/templates/PricingPage.tsx',
  },
  ABOUT_US: {
    EN: 'about-us',
    FR: 'propos-de-nous',
    COMPONENT: './src/templates/AboutUsPage.tsx',
  },
  NOTIFICATIONS: {
    EN: 'notifications',
    FR: 'avis',
    COMPONENT: './src/templates/NotificationsPage.tsx',
  },
  PRODUCT_HELP: {
    EN: 'help/product-help',
    FR: 'aider/aide-produit',
    COMPONENT: './src/templates/help/ProductHelpPage.tsx',
  },
  SEND_FEEDBACK: {
    EN: 'help/send-feedback',
    FR: 'aider/envoyer-des-commentaires',
    COMPONENT: './src/templates/help/SendFeedbackPage.tsx',
  },
  PAYMENT_PAGE: {
    EN: 'payment',
    FR: 'paiement',
    COMPONENT: './src/templates/PaymentPage.tsx',
  },
  CONFIRMATION_PAGE: {
    EN: 'confirmation',
    FR: 'confirmation',
    COMPONENT: './src/templates/ConfirmationPage.tsx',
  },
  ERROR_PAGE: {
    EN: 'error',
    FR: 'erreur',
    COMPONENT: './src/templates/GenericErrorPage.tsx',
  },
  USER: {
    EN: 'user', // The name of the folder in the pages folder of the project
    FR: 'utilisateur',
    COMPONENT: './src/templates/user/UserPage.tsx',
    PAGES: {
      // The list of components under the user route (consistent with the routes specified in the component)
      USER_PROFILE: {
        EN: '/profile',
        FR: '/profil',
      },
      USER_PROFILE_EDIT: {
        EN: '/edit-profile',
        FR: '/editer-le-profil',
      },
      USER_SETTINGS: {
        EN: '/settings',
        FR: '/reglages',
      },
      AUTHORIZATIONS: {
        EN: '/authorizations',
        FR: '/autorisations',
      },
    },
  },
};

export enum MODAL_WINDOW_TYPE {
  INFORMATION = 'INFORMATION',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  SAMPLE_DATA = 'SAMPLE_DATA',
}

// Modal Window Event Codes
export enum MODAL_EVENT_CODES {
  NO_EVENT = 'NO_EVENT',
  CANCEL_BUTTON = 'CANCEL_BUTTON',
  USER_OFFLINE = 'USER_OFFLINE',
}

export enum ERROR_ENTITY {
  USER_DATA = 'USER_DATA',
  EDIT_USER_PROFILE = 'EDIT_USER_PROFILE',
  PRODUCT = 'PRODUCT',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  CART_ITEM = 'CART_ITEM',
  ADD_TO_CART = 'ADD_TO_CART',
  PAYMENT_SUBSCRIPTION = 'PAYMENT_SUBSCRIPTION',
  GEY_KEYS = 'GEY_KEYS',
  PRIMARY_KEY = 'PRIMARY_KEY',
  SECONDARY_KEY = 'SECONDARY_KEY',

  ELIGIBILITY_CHECK = 'ELIGIBILITY_CHECK',
  PREPARE_PAYMENT = 'PREPARE_PAYMENT',
  SEND_ORDER_INFORMATION = 'SEND_ORDER_INFORMATION',
  UPDATE_ORDER = 'UPDATE_ORDER',
  CLEAR_SESSION = 'CLEAR_SESSION',
  GET_SESSION = 'GET_SESSION',
}

export enum ERROR_DISPLAY_TYPE {
  MODAL_WINDOW = 'MODAL_WINDOW',
  ERROR_PAGE = 'ERROR_PAGE',
}

export enum ERROR_TITLE {
  INFORMATION_ERROR = 'INFORMATION_ERROR',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

export enum ERROR_RESPONSE_CODES {
  INVALID_INPUT_ERROR = 'INVALID_INPUT_ERROR',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  REDIRECT_TO_HOMEPAGE = 'REDIRECT_TO_HOMEPAGE',
  SEND_INFORMATION_ERROR = 'SEND_INFORMATION_ERROR',
}

export enum ERROR_MESSAGE {
  ERROR_UNABLE_TO_PROCEED = 'ERROR_UNABLE_TO_PROCEED',
  ERROR_TRANSACTION_DECLINED = 'ERROR_TRANSACTION_DECLINED',
  ERROR_TRANSACTION_DECLINED_RIGHT_PART = 'ERROR_TRANSACTION_DECLINED_RIGHT_PART',
  ERROR_SESSION_EXPIRED = 'ERROR_SESSION_EXPIRED',
  ERROR_TECHNICAL_ERROR = 'ERROR_TECHNICAL_ERROR',
}

/**
 * Helper function to generate page links for website routes
 *
 * @param lang            language of page (en or fr)
 * @param page            page to load from SITE_CONFIG (i.e. 'FAQ')
 * @param subPage         subPage to load from SITE_CONFIG where applicable (i.e. USER_PROFILE if page is USER)
 * @param includePrefix   include the English or French URL prefix
 * @returns
 */
export const getPageLink = (
  lang: string,
  page: string,
  subPage = undefined,
  includePrefix = true,
): string => {
  return (
    (includePrefix ? LANG_CONFIG[lang.toUpperCase() + '_Prefix'] : '') +
    SITE_CONFIG[page][lang.toUpperCase()] +
    (subPage ? SITE_CONFIG[page]['PAGES'][subPage][lang.toUpperCase()] : '')
  );
};

// Get user data
export const GQL_GET_USER_DATA = gql`
  query {
    user {
      firstName
      lastName
      company
      phone
      authorizedRequestorNumber
      isAuthorized
      registeredOn
      cart {
        productId
        name
      }
      subscriptions {
        id
        productId
        name
        createdOn
      }
    }
  }
`;

// Edit user profile
export const GQL_EDIT_USER_PROFILE = gql`
  mutation ($user: UserInput!) {
    setUserProfile(user: $user) {
      firstName
      lastName
      company
      phone
      authorizedRequestorNumber
    }
  }
`;

// Get all products
export const GQL_GET_ALL_PRODUCTS = gql`
  query {
    dataProducts {
      name
      description
      productId
    }
  }
`;

// Get product details
export const GQL_GET_PRODUCT_DATA = gql`
  query dataProductsDetails($productIds: [String!]!) {
    dataProductsDetails(productIds: $productIds) {
      name
      description
      productId
      sample
      tags
      products {
        productId
        production
        apis {
          version
          specification
        }
      }
    }
  }
`;

// Do Search
export const GQL_DO_SEARCH = gql`
  query searchDataProducts($search: String!) {
    searchDataProducts(search: $search) {
      name
      description
      productId
    }
  }
`;

// Add to cart
export const GQL_ADD_TO_CART = gql`
  mutation ($productId: String!) {
    addToCart(productId: $productId) {
      productId
      name
    }
  }
`;

// Remove to cart
export const GQL_REMOVE_FROM_CART = gql`
  mutation ($productId: String!) {
    removeFromCart(productId: $productId) {
      productId
      name
    }
  }
`;

// Clear to cart
export const GQL_CLEAR_CART = gql`
  mutation {
    clearCart {
      productId
      name
    }
  }
`;

// Subscribe
export const GQL_SUBSCRIBE = gql`
  mutation {
    subscribe {
      id
      productId
      createdOn
    }
  }
`;

// Get Subscription keys
export const GQL_GET_SUBSCRIPTION_KEYS = gql`
  query subscriptionKeys($productId: String!) {
    subscriptionKeys(productId: $productId) {
      primaryKey
      secondaryKey
    }
  }
`;

// Regenerate primary key
export const GQL_REGENERATE_PRIMARY_KEY = gql`
  mutation ($productId: String!) {
    regenerateSubscriptionPrimaryKey(productId: $productId)
  }
`;

// Regenerate secondary key
export const GQL_REGENERATE_SECONDARY_KEY = gql`
  mutation ($productId: String!) {
    regenerateSubscriptionSecondaryKey(productId: $productId)
  }
`;
