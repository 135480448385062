import React, { ReactComponentElement } from 'react';
import {
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_WELCOME_MESSAGE,
  CONTEXT_MENU_SET_OPEN_STATE,
  SET_USER_INFO,
  SET_ALL_PRODUCTS_DATA,
  SET_PRODUCT_DATA,
  SET_SEARCH_KEYWORD,
  SET_SEARCH_DATA,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SELECT_PRODUCT_API_VERSION,
  SET_CONFIRMATION_NUMBER,
  SET_SUBSCRIPTION_KEYS,
  SET_MODAL_WINDOW_OPEN,
  SET_MODAL_WINDOW_CLOSE,
  SET_ERROR_PAGE_INFO,
  SET_DATA_SAMPLE,
  CONTEXT_MENU_SET_CLOSE_ALL_STATE,
} from './actions/ActionTypes';
import { MODAL_EVENT_CODES } from '../constants';

export const GlobalStateContext = React.createContext(undefined);
export const GlobalDispatchContext = React.createContext(undefined);

/**
 * Initial states
 */
const initialState = {
  welcomeMessage: '',
  loading: false,

  userProfile: {
    firstName: null,
    lastName: null,
    company: null,
    phone: null,
    authorizedRequestorNumber: null,
    isAuthorized: false,
    registeredOn: null,
    cart: [],
    subscriptions: {},
    notifications: [],
  },

  authorizedRequestorNumberStatic: null,

  allProducts: [],
  products: {},
  searchKeyword: '',
  searchData: [],

  contextMenu: {
    documentationOpen: false,
    helpOpen: false,
    userOpen: false,
    cartOpen: false,
  },

  selectedProductAPI: {},

  confirmationNumber: '',

  apiKeys: {
    aris: {
      primaryKey: '',
      secondaryKey: '',
    },
    'aris-uat': {
      primaryKey: '',
      secondaryKey: '',
    },
  },
  dataSample: {
    selectedVersion: 0,
  },
  modalWindow: {
    isOpen: false,
    title: '',
    message: '',
    closeButtonTitle: '',
    eventCode: MODAL_EVENT_CODES.NO_EVENT,
    closeDisabled: false,
    customButtons: '',
    useHeader: false,
    headerText: '',
    productDetailsURL: '',
    productId: '',
  },
  errorPage: {
    title: '',
    errorDescription: '',
    backButtonUrl: '',
  },
};

/**
 * State Reducers
 *
 * @param state
 * @param action
 */
const reducer = (state, action) => {
  switch (action.type) {
    // Site
    case SET_LOADING_ON: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_LOADING_OFF: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_WELCOME_MESSAGE: {
      return {
        ...state,
        welcomeMessage: action.welcomeMessage,
      };
    }

    case SET_USER_INFO: {
      let userProfile;

      if (action.userProfile === null) {
        userProfile = { ...initialState.userProfile };
      } else {
        userProfile = {
          ...initialState.userProfile,
          ...action.userProfile,
        };
      }

      const notifications = [];
      if (0 in userProfile.subscriptions) {
        notifications.push({
          message: 'PRODUCT_PURCHASED',
          name: userProfile.subscriptions[0].name,
          createdOn: userProfile.subscriptions[0].createdOn,
        });
      }

      const updatedState = {
        ...state,
        userProfile: {
          ...userProfile,
          notifications: [...notifications],
        },
      };

      if (
        'updateAuthorizedRequestorNumberStatic' in action &&
        action.updateAuthorizedRequestorNumberStatic === false
      ) {
        return {
          ...updatedState,
        };
      }

      return {
        ...updatedState,
        authorizedRequestorNumberStatic: userProfile.authorizedRequestorNumber,
      };
    }

    case SELECT_PRODUCT_API_VERSION: {
      return {
        ...state,
        selectedProductAPI: {
          ...state.selectedProductAPI,
          [action.productId]: action.productAPIVersion,
        },
      };
    }

    case ADD_TO_CART: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          cart: [...state.userProfile.cart, ...action.productsToAdd],
        },
      };
    }

    case REMOVE_FROM_CART: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          cart: [...action.cart],
        },
      };
    }

    case SET_SUBSCRIPTION_KEYS: {
      const primaryKey = action?.primaryKey
        ? action?.primaryKey
        : state.apiKeys[action.productId].primaryKey;
      const secondaryKey = action?.secondaryKey
        ? action?.secondaryKey
        : state.apiKeys[action.productId].secondaryKey;
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          [action.productId]: {
            primaryKey: primaryKey,
            secondaryKey: secondaryKey,
          },
        },
      };
    }

    case SET_CONFIRMATION_NUMBER: {
      return {
        ...state,
        confirmationNumber: action.confirmationNumber,
      };
    }

    case SET_ALL_PRODUCTS_DATA: {
      return {
        ...state,
        allProducts: [...action.allProducts],
      };
    }

    case SET_PRODUCT_DATA: {
      const selectedProductAPI = {};

      if (!state.selectedProductAPI.length) {
        for (const k in action.productData.products) {
          selectedProductAPI[action.productData.products[k].productId] =
            action.productData.products[k].apis[0].version;
        }
      }

      return {
        ...state,
        products: {
          ...state.products,
          [action.productId]: { ...action.productData },
        },
        selectedProductAPI: { ...selectedProductAPI },
      };
    }

    case SET_SEARCH_KEYWORD: {
      return {
        ...state,
        searchKeyword: action.searchKeyword,
      };
    }

    case SET_SEARCH_DATA: {
      return {
        ...state,
        searchData: [...action.searchData],
      };
    }

    case SET_DATA_SAMPLE: {
      return {
        ...state,
        dataSample: { ...action.dataSample },
      };
    }

    case CONTEXT_MENU_SET_OPEN_STATE: {
      return {
        ...state,
        contextMenu: {
          ...initialState.contextMenu,
          ...action.openMenu,
        },
      };
    }

    case CONTEXT_MENU_SET_CLOSE_ALL_STATE: {
      return {
        ...state,
        contextMenu: {
          ...initialState.contextMenu,
        },
      };
    }

    case SET_MODAL_WINDOW_OPEN: {
      return {
        ...state,
        modalWindow: {
          ...state.modalWindow,
          isOpen: true,
          type: action.modalWindow.type,
          message: action.modalWindow.message,
          closeButtonTitle: action.modalWindow?.closeButtonTitle
            ? action.modalWindow?.closeButtonTitle
            : '',
          closeDisabled: action.modalWindow?.closeDisabled
            ? action.modalWindow?.closeDisabled
            : false,
          customButtons: action.modalWindow?.customButtons
            ? action.modalWindow?.customButtons
            : '',
          useHeader: action.modalWindow?.useHeader
            ? action.modalWindow?.useHeader
            : false,
          headerText: action.modalWindow?.headerText
            ? action.modalWindow?.headerText
            : '',
          productDetailsURL: action.modalWindow?.productDetailsURL
            ? action.modalWindow?.productDetailsURL
            : '',
          productId: action.modalWindow?.productId
            ? action.modalWindow?.productId
            : '',
        },
      };
    }
    case SET_MODAL_WINDOW_CLOSE: {
      return {
        ...state,
        modalWindow: {
          ...initialState.modalWindow,
        },
      };
    }

    case SET_ERROR_PAGE_INFO: {
      return {
        ...state,
        errorPage: {
          title: action.errorPage.title,
          errorDescription: action.errorPage.errorDescription,
          backButtonUrl: action.errorPage.backButtonUrl,
        },
      };
    }

    default:
      throw new Error('Bad Action Type');
  }
};

/**
 * Main GlobalContextProvider
 *
 * @param children
 * @constructor
 */
const GlobalContextProvider = ({ children }): ReactComponentElement<any> => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
